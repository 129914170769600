import { Formik, Field } from "formik";
import * as Yup from "yup";
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { functions } from "../../firebase";
import { AuthContext } from "../../Providers/UserProvider";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  format,
  addDays,
  addHours,
  setMinutes,
  addMinutes,
  isAfter,
} from "date-fns";
import JobsTableComponent from "./JobsTable";
import CancelledJobsComponent from "./CancelledTable";
import Loader from "../../Shared/Loader";
import Calendar from "../../Shared/Calendar";
import { showError } from "../../utils/ui";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import newDate from "../../utils/newDate";

const JobsPage = () => {
  const { jobsRef, user } = useContext(AuthContext);
  const [jobs, setJobs] = useState();
  const [activeJob, setActiveJob] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [createJobModalVisibility, setCreateJobModalVisibility] =
    useState(false);

  const [openTab, setOpenTab] = React.useState(1);

  const [showCancelJobModal, setShowCancelJobModal] = useState(false);

  const [cancelationReason, setCancellationReason] = useState("");

  const jobSchema = Yup.object().shape({
    type: Yup.string().required("This field is required"),
    status: Yup.string().required("This field is required"),
    date: Yup.string().required("This input is required"),
  });

  const orderSchema = Yup.object({
    contact: Yup.string().required("This input is required"),
    customerName: Yup.string().required("This input is required"),
    type: Yup.string().required("This input is required"),
    damage: Yup.string().required("This input is required"),
    date: Yup.string()
      .required("This input is required")
      .test(
        "is-greater",
        "Appointment time has to be at least 24hrs in advance",
        function (value) {
          return isAfter(
            newDate(value).getTime(),
            new Date().setDate(new Date().getDate() + 1)
          );
        }
      ),
    vehicle: Yup.object({
      year: Yup.number().required("This input is required"),
      make: Yup.string().required("This input is required"),
      model: Yup.string().required("This input is required"),
      insurance: Yup.string().required("This input is required"),
    }),
  });

  const shopId = useMemo(() => (user ? user.claims.shopId : null), [user]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await functions.httpsCallable(
          "fetchShopJobsWithTechs"
        )();
        setJobs(data);
      } catch (error) {
        alert(
          "There was an error fetching your jobs. Please reach out to an admin."
        );
      }
      setLoading(false);
    })();
  }, [activeJob, createJobModalVisibility]);

  const formSubmit = useCallback(
    (vals) => {
      setValidatingRealTime(true);
      if (activeJob?.id) {
        jobsRef.doc(activeJob.id).update({
          type: vals.type,
          status: vals.status,
          date: vals.date,
        });
      }
      setShowModal(false);
      setActiveJob({});
    },
    [activeJob, jobsRef]
  );

  const [validatingRealTime, setValidatingRealTime] = useState(false);

  const [validatingAddShop, setValidatingAddShop] = useState(false);

  const openCreateJobModal = () => {
    setCreateJobModalVisibility(true);
  };

  const closeCreateJobModal = () => {
    setCreateJobModalVisibility(false);
  };

  const handleCalendarChange = (formik, [date]) => {
    if (!date || !Date.parse(date)) {
      formik.setFieldValue("date", "");
      return;
    }
    const dateValue = format(date, "yyyy-MM-dd HH:mm");
    formik.setFieldValue("date", dateValue);
  };

  const getMinDate = () => {
    const curDate = new Date();
    const nextDate = addMinutes(
      addDays(curDate, 1),
      curDate.getMinutes() % 15 === 0 ? 0 : 15 - (curDate.getMinutes() % 15)
    );
    return format(nextDate, "yyyy-MM-dd HH:mm");
  };

  const handleCalendarUpdate = (formik, [date]) => {
    try {
      const formattedTime = format(date, "yyyy-MM-dd' 'HH:mm");
      if (Date.parse(date)) {
        formik.setFieldValue("date", formattedTime);
      }
    } catch (error) {
      formik.setFieldValue("date", "");
    }
  };

  const activeJobs = jobs?.filter((job) => job.status === "active") || [];

  const pendingJobs = jobs?.filter((job) => job.status === "pending") || [];

  const completeJobs = jobs?.filter((job) => job.status === "complete") || [];

  const cancelledJobs = jobs?.filter((job) => job.status === "cancelled") || [];

  const calendarJobs =
    jobs?.filter(
      (job) => job.status === "pending" || job.status === "active"
    ) || [];

  const types = [
    {
      value: "Estimate",
      title: "Estimate",
      description: "This job is an estimate",
    },
    {
      value: "Repair",
      title: "Repair",
      description: "This job is a repair",
    },
  ];

  const damage = [
    {
      value: "Hail",
      title: "Hail",
      description: "This job is for hail damage",
    },
    {
      value: "Minor Damage",
      title: "Minor Damage",
      description: "This job is for minor damage",
    },
  ];

  const dropoff = [
    {
      value: "Currently dropped",
      title: "Currently dropped",
    },
    {
      value: "Will be dropped",
      title: "Will be dropped",
    },
    {
      value: "Not dropping",
      title: "Not dropping",
    },
  ];

  const cancelJobSubmit = (evt) => {
    evt.preventDefault();
    (async () => {
      try {
        setLoading(true);
        await jobsRef.doc(activeJob.id).update({
          status: "cancelled",
          cancellationReason: cancelationReason,
        });
        setShowCancelJobModal(false);
        setActiveJob(null);
        setCancellationReason();
        setJobs(
          [...jobs].map((job) =>
            job.id === activeJob.id ? { ...job, status: "cancelled" } : job
          )
        );
      } catch (error) {
        showError(error, "There was an error cancelling this job.");
      }
      setLoading(false);
    })();
  };

  return (
    <div className="container w-full mx-auto pt-20">
      <div className="flex flex-col">
        <button
          type="button"
          className="bg-green-400 hover:bg-green-900 text-white font-bold py-2 px-4 w-72 text-center mx-auto mt-10"
          onClick={() => openCreateJobModal()}
        >
          CREATE JOB
        </button>
        <div className="overflow-x-auto mx-8 py-8">
          <Calendar
            onEventClick={(e) => {
              const thisActiveJob = jobs.find(({ id }) => id === e);
              if (thisActiveJob) {
                setActiveJob(thisActiveJob);
                setShowModal(true);
              }
            }}
            events={calendarJobs}
          />
          <div className="flex flex-wrap pl-2.5 pr-2.5 mt-6">
            <div className="w-full">
              <ul
                className=" mb-0 list-none pt-3 pb-4 grid grid-cols-4 gap-4"
                role="tablist"
              >
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center relative">
                  <button
                    className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal w-full ${
                      openTab === 1
                        ? "text-white bg-yellow-400"
                        : "text-blue-600 bg-white"
                    }`}
                    onClick={() => {
                      setOpenTab(1);
                    }}
                  >
                    Pending
                  </button>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center relative">
                  <button
                    className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal w-full ${
                      openTab === 2
                        ? "text-white bg-blue-500"
                        : "text-blue-600 bg-white"
                    }`}
                    onClick={() => {
                      setOpenTab(2);
                    }}
                  >
                    Active
                  </button>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center relative">
                  <button
                    className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal w-full ${
                      openTab === 3
                        ? "text-white bg-green-500"
                        : "text-blue-600 bg-white"
                    }`}
                    onClick={() => {
                      setOpenTab(3);
                    }}
                  >
                    Complete
                  </button>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center relative">
                  <button
                    className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal w-full ${
                      openTab === 4
                        ? "text-white bg-red-500"
                        : "text-blue-600 bg-white"
                    }`}
                    onClick={() => {
                      setOpenTab(4);
                    }}
                  >
                    Cancelled
                  </button>
                </li>
              </ul>
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="px-1 flex-auto">
                  <div className="tab-content tab-space">
                    <div
                      className={openTab === 1 ? "block" : "hidden"}
                      id="pending"
                    >
                      <JobsTableComponent
                        setShowModal={setShowModal}
                        setActiveJob={setActiveJob}
                        setShowCancelJobModal={setShowCancelJobModal}
                        jobs={pendingJobs}
                      />
                    </div>
                    <div
                      className={openTab === 2 ? "block" : "hidden"}
                      id="active"
                    >
                      <JobsTableComponent
                        setShowModal={setShowModal}
                        setActiveJob={setActiveJob}
                        setShowCancelJobModal={setShowCancelJobModal}
                        jobs={activeJobs}
                      />
                    </div>
                    <div
                      className={openTab === 3 ? "block" : "hidden"}
                      id="complete"
                    >
                      <JobsTableComponent
                        setShowModal={setShowModal}
                        setActiveJob={setActiveJob}
                        setShowCancelJobModal={setShowCancelJobModal}
                        jobs={completeJobs}
                      />
                    </div>
                    <div
                      className={openTab === 4 ? "block" : "hidden"}
                      id="cancelled"
                    >
                      <CancelledJobsComponent jobs={cancelledJobs} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading && (
          <div className="text-center position-absolute;">
            <Loader />
          </div>
        )}
      </div>
      {createJobModalVisibility && (
        <>
          {!shopId && (
            <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
              <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
                <i className="fas fa-circle-notch fa-spin fa-5x"></i>
              </span>
            </div>
          )}
          <div className="fixed w-full h-full top-0 left-0 flex z-50 ">
            <div className="fixed w-full h-full overflow-y-auto bg-gray-100 bg-cover pb-12">
              <div className="fixed justify-start p-2 border-b border-solid border-gray-300 bg-white w-full">
                <button
                  className="text-blue-500 background-transparent font-bold text-sm outline-none focus:outline-none"
                  type="button"
                  onClick={closeCreateJobModal}
                >
                  <FontAwesomeIcon
                    className="text-base mr-1"
                    icon={faChevronLeft}
                  />
                  <span>Back</span>
                </button>
              </div>
              <div className="md:mt-10 mx-auto max-w-7xl	">
                <div className="pt-14 container mx-auto h-auto pb-4">
                  <div className="text-center md:mb-5">
                    <div
                      className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Notice
                      </span>
                      <span className="font-semibold italic uppercase mr-2 flex-auto">
                        Appointments scheduled under 48 hrs in advance are not guaranteed
                      </span>
                      {/* {region.phone && (
                        <div className="mr-2 flex">
                          <FontAwesomeIcon
                            className="text-base mr-1"
                            icon={faPhone}
                          />
                          <span>{region.phone}</span>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="md:mt-0 md:col-span-2 text-left">
                    <div className="shadow overflow-hidden sm:rounded-md">
                      <Formik
                        initialValues={{
                          type: "",
                          damage: "",
                          date: format(
                            addHours(setMinutes(addDays(new Date(), 1), 0), 1),
                            "yyyy-MM-dd HH:mm"
                          ),
                          customerName: "",
                          shop: shopId,
                          contact: "",
                          vehicle: {
                            year: "",
                            make: "",
                            model: "",
                            insurance: "",
                          },
                          assignee: "",
                          notes: "",
                          status: "pending",
                          waiting: false,
                          dropped: "Currently dropped",
                        }}
                        validateOnBlur={false}
                        validateOnChange={validatingAddShop}
                        onSubmit={(values) => {
                          jobsRef
                            .add({
                              ...values,
                              date: format(
                                new Date(values.date),
                                "yyyy-MM-dd HH:mm"
                              ),
                              type: `${values.type} for ${values.damage}`,
                            })
                            .then(() => {
                              closeCreateJobModal();
                            });
                        }}
                        validationSchema={orderSchema}
                      >
                        {(formik) => (
                          <>
                            <div className="px-4 py-5 bg-white sm:p-6">
                              <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="job_type"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Job Type
                                  </label>
                                  <select
                                    name="job_type"
                                    id="job_type"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "type",
                                        event.target.value
                                      )
                                    }
                                  >
                                    <option
                                      className="placeholder-gray-600"
                                      value=""
                                    >
                                      Select job type
                                    </option>
                                    {types.map((type) => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.title}
                                      </option>
                                    ))}
                                  </select>
                                  {formik.errors.type && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.type}
                                    </p>
                                  )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="job_type"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Damage Type
                                  </label>
                                  <select
                                    name="damage_type"
                                    id="damage_type"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "damage",
                                        event.target.value
                                      )
                                    }
                                  >
                                    <option
                                      className="placeholder-gray-600"
                                      value=""
                                    >
                                      Select damage type
                                    </option>
                                    {damage.map((type) => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.title}
                                      </option>
                                    ))}
                                  </select>
                                  {formik.errors.damage && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.damage}
                                    </p>
                                  )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="date"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Date
                                  </label>
                                  <Flatpickr
                                    data-enable-time
                                    value={formik.values.date}
                                    options={{
                                      minuteIncrement: 15,
                                      altInput: true,
                                      altFormat: "M j\\, Y h\\:i K",
                                      dateFormat: "Y\\-m\\-d\\ H\\:i",
                                      minDate: getMinDate(),
                                    }}
                                    name="date"
                                    className={`p-2 mt-1 w-full rounded-md border-lightGray shadow-sm text-sm`}
                                    onChange={(date) => {
                                      handleCalendarChange(formik, date);
                                    }}
                                  />
                                  {formik.errors.date && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.date}
                                    </p>
                                  )}
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <label
                                    htmlFor="make"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Vehicle Make
                                  </label>
                                  <input
                                    type="text"
                                    name="make"
                                    id="make"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "vehicle.make",
                                        event.target.value
                                      )
                                    }
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                  {formik.errors.vehicle?.make && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.vehicle.make}
                                    </p>
                                  )}
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-1">
                                  <label
                                    htmlFor="year"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Vehicle Year
                                  </label>
                                  <input
                                    type="text"
                                    name="year"
                                    id="year"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "vehicle.year",
                                        event.target.value
                                      )
                                    }
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                  {formik.errors.vehicle?.year && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.vehicle.year}
                                    </p>
                                  )}
                                </div>
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <label
                                    htmlFor="model"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Vehicle Model
                                  </label>
                                  <input
                                    type="text"
                                    name="model"
                                    id="model"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "vehicle.model",
                                        event.target.value
                                      )
                                    }
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                  {formik.errors.vehicle?.model && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.vehicle.model}
                                    </p>
                                  )}
                                </div>
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <label
                                    htmlFor="insurance"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Vehicle Insurance
                                  </label>
                                  <input
                                    type="text"
                                    name="insurance"
                                    id="insurance"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "vehicle.insurance",
                                        event.target.value
                                      )
                                    }
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                  {formik.errors.vehicle?.insurance && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.vehicle.insurance}
                                    </p>
                                  )}
                                </div>

                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                  <label
                                    htmlFor="contact"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Primary Contact
                                  </label>
                                  <input
                                    required
                                    type="text"
                                    name="contact"
                                    id="contact"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "contact",
                                        event.target.value
                                      )
                                    }
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                  {formik.errors.contact && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.contact}
                                    </p>
                                  )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="customerName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Customer Name
                                  </label>
                                  <input
                                    type="text"
                                    name="customerName"
                                    id="customerName"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "customerName",
                                        event.target.value
                                      )
                                    }
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                  {formik.errors.customerName && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.customerName}
                                    </p>
                                  )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="dropoff"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Vehicle Dropoff
                                  </label>
                                  <select
                                    name="dropoff"
                                    id="dropoff"
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "dropped",
                                        event.target.value
                                      )
                                    }
                                  >
                                    <option
                                      className="placeholder-gray-600"
                                      value=""
                                    >
                                      Select Dropoff
                                    </option>
                                    {dropoff.map((type) => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.title}
                                      </option>
                                    ))}
                                  </select>
                                  {formik.errors.dropped && (
                                    <p className="text-red-500 text-xs italic mt-2">
                                      {formik.errors.dropped}
                                    </p>
                                  )}
                                </div>
                                <div className="col-span-6 my-auto">
                                  <div>
                                    <span className="text-sm font-medium text-gray-700">
                                      Customer waiting:
                                    </span>
                                    <Field
                                      className="ml-3"
                                      type="checkbox"
                                      name="waiting"
                                    />
                                  </div>
                                </div>
                                <div className="col-span-6">
                                  <label
                                    htmlFor="notes"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Notes
                                  </label>
                                  <input
                                    required
                                    type="text"
                                    name="notes"
                                    id="notes"
                                    onChange={(event) =>
                                      formik.setFieldValue(
                                        "notes",
                                        event.target.value
                                      )
                                    }
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                              <button
                                type="button"
                                onClick={() => {
                                  setValidatingAddShop(true);
                                  formik.submitForm();
                                }}
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                Create Job
                              </button>
                            </div>
                          </>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto">
                  <div className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-assignee"
                        >
                          Assignee
                        </label>
                        <p
                          className="appearance-none bg-transparent border-none w-full text-gray-700 py-3 leading-tight focus:outline-none"
                          id="grid-assignee"
                        >
                          {activeJob.tech?.name || "None Assigned"}
                        </p>
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-vehicle"
                        >
                          Vehicle Details
                        </label>
                        <p
                          className="appearance-none bg-transparent border-none w-full text-gray-700 py-3 leading-tight focus:outline-none"
                          id="grid-vehicle"
                        >
                          {activeJob.vehicle?.year +
                            " " +
                            activeJob.vehicle?.make +
                            " " +
                            activeJob.vehicle?.model}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/3 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-insurance"
                        >
                          Insurance
                        </label>
                        <p
                          className="appearance-none bg-transparent border-none w-full text-gray-700 py-3 leading-tight focus:outline-none"
                          id="grid-insurance"
                        >
                          {activeJob.vehicle?.insurance}
                        </p>
                      </div>
                      <div className="w-full md:w-1/3 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-contact"
                        >
                          Contact
                        </label>
                        <p
                          className="appearance-none bg-transparent border-none w-full text-gray-700 py-3 leading-tight focus:outline-none"
                          id="grid-contact"
                        >
                          {activeJob.contact}
                        </p>
                      </div>
                      <div className="w-full md:w-1/3 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-invoiced"
                        >
                          Invoiced
                        </label>
                        <p
                          className="appearance-none bg-transparent border-none w-full text-gray-700 py-3 leading-tight focus:outline-none"
                          id="grid-invoiced"
                        >
                          {activeJob.invoiced === true ? "True" : "False"}
                        </p>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        type: activeJob?.type,
                        status: activeJob?.status,
                        date: activeJob?.date,
                      }}
                      onSubmit={formSubmit}
                      validationSchema={jobSchema}
                      validateOnBlur={false}
                      validateOnChange={validatingRealTime}
                    >
                      {(props) => (
                        <div className="flex flex-wrap">
                          <div className="w-full md:w-1/3 pr-1 mb-6 md:mb-0">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-type"
                            >
                              Type: <strong>{props.values.type}</strong>
                            </label>
                            <div className="relative">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-type"
                                onChange={(option) => {
                                  props.handleChange("type")(
                                    option.target.value
                                  );
                                }}
                              >
                                <option value="" label="Select a type" />
                                <option
                                  value="Estimate"
                                  label="Estimate"
                                ></option>
                                <option value="Repair" label="Repair"></option>
                              </select>
                            </div>
                          </div>
                          <div className="w-full md:w-1/3 pr-1 mb-6 md:mb-0">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-status"
                            >
                              Status: <strong>{props.values.status}</strong>
                            </label>
                            <div className="relative">
                              <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-status"
                                onChange={(option) => {
                                  props.handleChange("status")(
                                    option.target.value
                                  );
                                }}
                              >
                                <option value="" label="Select a status" />
                                <option
                                  value="pending"
                                  label="Pending"
                                ></option>
                                <option value="active" label="Active"></option>
                                <option
                                  value="complete"
                                  label="Complete"
                                ></option>
                              </select>
                            </div>
                          </div>
                          <div className="w-full md:w-1/3">
                            <label
                              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-date"
                            >
                              Date
                            </label>
                            <Flatpickr
                              data-enable-time
                              value={
                                props.values.date
                                  ? newDate(props.values.date)
                                  : ""
                              }
                              options={{
                                minuteIncrement: 15,
                                altInput: true,
                                altFormat: "M j\\, Y h\\:i K",
                                dateFormat: "Y\\-m\\-d\\ H\\:i",
                                defaultDate: props.values.date,
                              }}
                              name="date"
                              className={`p-2 mt-1 w-full rounded-md border-lightGray shadow-sm text-sm`}
                              onChange={(date) => {
                                handleCalendarUpdate(props, date);
                              }}
                            />
                            {props.errors.date && (
                              <p className="text-red-500 text-xs italic mt-2">
                                {props.errors.date}
                              </p>
                            )}
                          </div>

                          <div className="w-full md:w-1/2 pr-1 mb-6 mt-4 md:mb-0">
                            <p className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                              Customer Name:
                            </p>
                            <p>
                              <strong> {activeJob.customerName}</strong>
                            </p>
                          </div>

                          <div className="w-full md:w-1/2 pr-1 mb-6 mt-4 md:mb-0">
                            <p className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                              Notes:
                            </p>
                            <p>
                              <strong> {activeJob.notes}</strong>
                            </p>
                          </div>

                          <div className="mt-10 mx-auto text-center">
                            <button
                              className="w-full bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => {
                                props.handleSubmit();
                                setValidatingRealTime(true);
                              }}
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setActiveJob(null);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
      {showCancelJobModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto">
                  <div className="w-full max-w-lg">
                    <h3>Are you sure you want to cancel this job?</h3>
                  </div>
                  <form onSubmit={cancelJobSubmit}>
                    <div className="w-full pt-6 max-w-lg px-3">
                      <label
                        htmlFor="cancelationNotes"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cancelation Reason
                      </label>
                      <input
                        required
                        type="text"
                        name="cancelationNotes"
                        id="cancelationNotes"
                        onChange={(event) =>
                          setCancellationReason(event.target.value)
                        }
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    {loading && <Loader />}
                    <div className="mt-10 mx-auto text-center">
                      <button
                        className="w-full bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Cancel Job
                      </button>
                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowCancelJobModal(false);
                      setActiveJob(null);
                      setCancellationReason();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </div>
  );
};
export default JobsPage;
